import React from "react";
import PageWrapper from "../components/PageWrapper";

const Introduction = () => {
  const playerOptions = {
    height: "500px", // Customize the height of the player
    width: "970px", // Customize the width of the player
    playerVars: {
      autoplay: 1,
    },
  };
  const videoId = "2w_xvmE7oEg"; // Replace this with your actual YouTube video ID

  return (
    <PageWrapper
      themeConfig={{
        headerClassName: "site-header--menu-right",
        footerStyle: "style3",
      }}
    >
      <div className="w-100">
        <iframe
          id={"youtube-iframe"}
          className="mt-20"
          src="https://www.youtube.com/embed/IfiPpWnlarY"
          allowFullScreen
        ></iframe>
      </div>
    </PageWrapper>
  );
};

export default Introduction;
